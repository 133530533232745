// Content
// =============================================================================
:root {
    // Code
    --code-theme-background : #222;
    --code-theme-comment    : #516e7a;
    --code-theme-function   : #f07178;
    --code-theme-keyword    : #c2e78c;
    --code-theme-operator   : #ffcb6b;
    --code-theme-punctuation: #89ddff;
    --code-theme-selector   : #ffcb6b;
    --code-theme-tag        : #f07178;
    --code-theme-text       : #f3f3f3;
    --code-theme-variable   : #ffcb6b;
}
